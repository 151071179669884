<template>
  <!-- <div
    class="header-banner-container"
    style="display: block;"
  >
        <img
          class="myimg"
          src="@/assets/images/home/gundong/ASN.png"
          alt=""
          style="text-align: center; margin: 0 auto; height: 140px"
        />

  </div>  -->

  <header class="header-container">
    <div class="header-logo">
      <img
        src="@/assets/images/home/logo-t.png"
        alt="logo"
        class="logo"
        @click="goHome"
      />
    </div>

    <ul class="header-menu">
      <li class="menu-item community" @click="menuClick('topic')">
        {{ $f("p140_t200", "话题") }}
      </li>
      <li class="menu-item browse" @click="menuClick('resource')">
        {{ $f("p140_t180", "浏览资源") }}
      </li>
      <!-- <li class="menu-item demand2" @click="menuClick('demand')">
        {{ $f("p140_t190", "赞助商的需求") }}
      </li> -->
      <li class="menu-item" @click="menuClick('perspective')">
        {{ $f("p152_t161", "洞察") }}
      </li>
      <!-- <span @click="menuClick('activity')">{{ $f("p140_t210", "活动") }}</span> -->
      <li class="menu-item" @click="menuClick('about')">
        {{ $f("p140_t170", "关于我们") }}
      </li>
      <li class="menu-item" @click="menuClick('chat-page')">
        AI Assistant
        <!-- Dynamic naming needs to be added -->
        <!-- {{ $f("p140_t170", "关于我们") }} -->
      </li>
    </ul>
    <!-- </nav> -->

    <!-- </div> -->
    <!-- <div class="menu-warp">
      <img src="@/assets/images/home/logo-t.png" alt="logo" class="logo"  @click="goHome">

      <ul class="menus dfc">
        <li v-for="menu in menus" :key="menu.key" class="menu" @click="menuClick(menu)">{{ menu.name }}</li>
      </ul>
    </div> -->

    <div class="header-right">
      <!-- <el-icon @click="changeModel()" style="margin:0 auto;margin-right:24px;">
        <Search />
      </el-icon> -->
      <template v-if="!userStore.isLogin">
        <span class="btn-pointer primary-btn-1" @click="gotoLogin">
          {{ $f("p140_t110", "登录") }}
        </span>

        <div
          class="btn-pointer primary-btn-2"
          style="display: inline-block; text-align: center"
          @click="gotoRegister"
        >
          {{ $f("p140_t100", "注册") }}
        </div>
      </template>
      <template v-else>
        <el-avatar
          shape="square"
          size="small"
          :src="avatarUrl"
          class="avatar"
        ></el-avatar>
        <el-dropdown @command="userActionClick">
          <div style="outline: none; cursor: pointer">
            <el-badge :is-dot="showDot" class="item">
              <span class="btn-pointer username">{{ userStore.userInfo.nickname || "-" }}</span>
            </el-badge>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="me">{{
                $f("p140_t120", "个人中心")
              }}</el-dropdown-item>
              <el-dropdown-item command="mymessage">
                <el-badge :is-dot="showDot" class="item">
                  {{ $f("p152_t610", "我的消息") }}
                </el-badge>
              </el-dropdown-item>
              <el-dropdown-item command="resource" v-if="userStore.userType === 1">
                {{ $f("p140_t130", "发布资源") }}
              </el-dropdown-item>
              <el-dropdown-item command="demand" v-if="userStore.userType === 2">
                {{ $f("p140_t140", "发布需求") }}
              </el-dropdown-item>
              <el-dropdown-item command="changePsw">{{
                $f("p140_t150", "修改密码")
              }}</el-dropdown-item>
              <el-dropdown-item command="logout">{{
                $f("p140_t160", "退出登录")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>

      <div style="margin: 0 0 0 30px" class="lang-warp dfc">
        <!--<img :title="$f('p140_t161', '跳转至日本站')" src="@/assets/images/home/lang.png" class="earth-btn" @click="gotoJpSite">-->
        <el-dropdown @command="changeLang">
          <img
            v-if="locale == 'zh-cn'"
            src="@/assets/images/language/zh-cn.png"
            class="btn-pointer earth-btn"
          />
          <img
            v-if="locale == 'en'"
            src="@/assets/images/language/en.png"
            class="btn-pointer earth-btn"
          />
          <img
            v-if="locale == 'jp'"
            src="@/assets/images/language/jp.png"
            class="btn-pointer earth-btn"
          />
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in langs"
                :key="item.key"
                :command="item.key"
                class="ignore"
              >
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div>
        <el-tooltip
          class="box-item"
          effect="dark"
          :show-arrow="false"
          :content="$f('p140_t101', '选择币种')"
          placement="left"
          :popper-options="{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          }"
        >
          <div style="margin: 0 0 0 30px" class="lang-warp dfc">
            <el-dropdown @command="changeCurrency">
              <!-- <img src="@/assets/images/home/lang.png" class="btn earth-btn" /> -->
              <!-- <img :src="getCurrencyInfo[0]" style="width: 25px" class="btn earth-btn" />CNY -->

              <span
                style="
                  cursor: pointer;
                  outline: none;
                  display: inline-block;
                  min-width: 80px;
                  font-size: 18px;
                "
                >{{ getCurrencyInfo[1] }}</span
              >
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in currencyTypeItems"
                    :key="item.key"
                    :command="item.value"
                    class="ignore"
                  >
                    <img :src="item.url" class="currency-icon" /> {{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-tooltip>
      </div>
    </div>
  </header>
  <!-- <div class="mask" ref="mask" style="display: none">
    <span
      class="input-warp resource-search"
      v-if="router.currentRoute.value.fullPath != '/portal/resource'"
    >
      <input
        style="font-size: 12px; padding: 5px 30px"
        v-model="keyword"
        type="text"
        :placeholder="$f('p046_t150', '输入资源名称、项目或地区')"
        @keydown.enter="gotoResource"
      />
      <el-icon @click="gotoResource" style="transform: translateX(-2.4rem)">
        <Search />
      </el-icon>
    </span>
  </div> -->
</template>

<script setup>
import { onMounted, ref, computed, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/user'
import { useFanyi } from '@/plugin/fanyi'
import { currencyType, langs } from '@/utils/common'
import { baseImgUrl, getSmallPicUrl } from '@/utils/index'
import { portalApi } from '@/api/all'
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import avatarImg from '@/assets/images/common/avatar.png'

const router = useRouter()
const userStore = useUserStore()
const { locale } = useFanyi()
const menuwidth = ref('600px')
const avatarUrl = ref(null)
const showDot = ref(false)
const isHeaderHidden = ref(false)
const lastScrollTop = ref(0)
const keyword = ref('')
const hasLogin = ref(userStore.isLogin)
const userType = ref(userStore.userType)
let timer = -1
const currencyTypeItems = computed(() => {
  return currencyType.map((item) => {
    return {
      ...item,
      url: new URL(`/src/assets/images/currency/${item.icon}.png`, import.meta.url).href,
    }
  })
})
function showHeaderNew () {
  if (router.currentRoute.value.fullPath != '/login') {
    const headerbannercontainer = document.querySelector('.header-banner-container')
    headerbannercontainer.style.height = 0
  }
}
const mask = ref(null)
function changeModel () {
  setTimeout(() => {
    mask.value.style.display = 'block'
    mask.value.style.transform = 'scale(1)'
    mask.value.style.opacity = '1'
  }, 150)
}
window.onclick = function (event) {
  if (event.target == mask.value) {
    mask.value.style.display = 'none'
    mask.value.style.opacity = '1'
  }
}

// const getCurrencyIcon = () => {
//   let val = localStorage.getItem("currency") || "c101";
//   let item = currencyType.filter((x) => x.value == val);
//   let c = `@/assets/images/home/${item.icon}.png`;
//   return c;
// };

const getCurrencyInfo = computed(() => {

  let defaultCurrency='c106'
  console.log('getCurrencyInfo',navigator.language)
  console.log('getCurrencyInfo',localStorage.getItem('currency'))
  if(!localStorage.getItem('currency'))
  {
    if(navigator.language.startsWith('zh')) {
      defaultCurrency='c102'
    } else if(navigator.language.startsWith('ja')) {
      defaultCurrency='c101'
    } 
  }


  // let val = localStorage.getItem('currency') || 'c101'
  let val = localStorage.getItem('currency') || defaultCurrency
  let item = currencyType.filter((x) => x.value == val)[0]
  console.log(item)
  let c = new URL(`/src/assets/images/currency/${item.icon}.png`, import.meta.url).href
  return [c, item.name]
})
// const getCurrencyText =computed( () => {
//   let val = localStorage.getItem("currency") || "c101";
//   let item = currencyType.filter(x => x.value == val)[0];
//   console.log(item)
//   let c = `/src/assets/images/currency/${item.icon}.png`;
//   console.log(c)
//   return c;
// });
const images = ['../../../../src/assets/images/home/gundong/ASN.png']
let imgsrc = ref(null)
onMounted(() => {
  //startGuidedHeader()
  // if (router.currentRoute.value.fullPath == '/login') {
  //   hideHeaderNew()
  // }
  // console.log('header mounted')
  // const myimg = document.querySelector('.myimg')
  // let index = 0
  // function changeImage () {
  //   imgsrc.value = `${images[index]}`
  //   console.log(myimg.src)
  //   index = (index + 1) % images.length
  // }
  // setInterval(changeImage, 3000)
  // // 初始化显示第一张图片
  // changeImage()

  // const headerBanner = document.querySelector('.header-banner-container')
  // const headercontainer = document.querySelector('.header-container')
  // const handleScroll = () => {
  //   if (window.scrollY > 100 || router.currentRoute.value.fullPath == '/login') {
  //     isHeaderHidden.value = true
  //     headercontainer.style.top = 0
  //     headerBanner.style.height = 0
  //   } else {
  //     isHeaderHidden.value = false
  //     headerBanner.style.height = '140px'
  //     headercontainer.style.top = '140px'
  //   }
  //   // lastScrollTop.value = scrollTop
  // }

  // window.addEventListener('scroll', handleScroll)

  let userInfo = userStore.userInfo
  if (userInfo && userInfo.info) {
    startLoop()
    getUnreadMessage()

    avatarUrl.value =
      JSON.parse(userInfo.info).headurl !== ''
        ? baseImgUrl + getSmallPicUrl(JSON.parse(userInfo.info).headurl)
        : avatarImg
  }
  adjustMenuWidth()
})

// function hideHeaderNew () {
//   document.querySelector('.header-banner-container').classList.add('hide')
//   document.querySelector('.header-container').style.top = 0
//   document.querySelector('.header-container').style.position = 'fixed'
// }

function startGuidedHeader () {
  if (hasLogin.value && router.currentRoute.value.fullPath == '/portal/home') {
    if (userType.value == 1 && !localStorage.getItem('hasGuidedHeader')) {
      console.log('用户已经登录，开始引导')
      const driverObj = driver({
        showProgress: true,
        overlayColor: '#92929',
        wait: 8000,
        steps: [
          {
            element: '.username',
            popover: {
              title: '资源方',
              description: '您可前往个人中心创建您的资源，保存或发布',
            },
          },
          {
            element: '.demand2',
            popover: {
              title: '在这里',
              description: '您可以浏览品牌需求，找到合适的赞助商',
            },
          },
          {
            element: '.browse',
            popover: {
              title: '在这里',
              description: '您的资源将展示在这里，被更多的赞助商发现',
            },
          },
          {
            element: '.community',
            popover: { title: '话题区', description: '您可以分享有趣的话题' },
          },
        ],
      })
      driverObj.drive()
      localStorage.setItem('hasGuidedHeader', 'true')
    } else if (userType.value == 2 && !localStorage.getItem('hasGuidedHeader')) {
      console.log('用户已经登录，开始引导')
      const driverObj = driver({
        showProgress: true,
        overlayColor: '#92929',
        steps: [
          {
            element: '.username',
            popover: { title: '赞助商', description: '您可前往个人中心发布需求' },
          },
          {
            element: '.demand2',
            popover: {
              title: '赞助商',
              description: '您发布的需求将展示在这里，被更多资源方发现',
            },
          },
          {
            element: '.browse',
            popover: {
              title: '在这里',
              description: '您可以浏览丰富的体育资源,涵盖运动员、协会、赛事等',
            },
          },
          {
            element: '.community',
            popover: { title: '话题区', description: '您可以分享有趣的话题' },
          },
          {
            element: '.resource-search',
            popover: { title: '搜索功能', description: '全球范围的资源都可以搜索到' },
          },
        ],
        wait: 8000,
      })
      driverObj.drive()
      localStorage.setItem('hasGuidedHeader', 'true')
    }
  } else {
    console.log('用户尚未登录，不进行引导')
  }
}
// 开始轮询获取未读数, 1分钟一次
function startLoop () {
  timer = setInterval(() => {
    getUnreadMessage()
  }, 180_000)
}

function gotoResource () {
  router.push({
    path: '/portal/resource',
    query: {
      keyword: keyword.value,
    },
  })
}

function stopLoop () {
  console.log('stop')
  clearInterval(timer)
}

watchEffect(() => {
  // console.debug('@sss = ', userStore.isTokenTimeout, userStore)
  if (userStore.isTokenTimeout) {
    stopLoop()
  }
})

function getUnreadMessage () {
  portalApi.queryUnreadMessageCount().then((res) => {
    console.log('getUnreadMessage', res)
    showDot.value = res.result > 0
  })
}

function adjustMenuWidth () {
  if (locale.value == 'zh-cn') {
    menuwidth.value = '500px'
  }
  if (locale.value == 'jp') {
    menuwidth.value = '500px'
  }
  if (locale.value == 'en') {
    menuwidth.value = '500px'
  }
}

// const menus = computed(() => [
//   {
//     name: f('p140_t170', '关于我们'),
//     key: 'about',
//   },
//   {
//     name: f('p140_t180', '浏览资源'),
//     key: 'resource',
//   },
//   {
//     name: f('p140_t190', '品牌需求'),
//     key: 'demand',
//   },
//   {
//     name: f('p140_t200', '话题'),
//     key: 'topic',
//   },
//   {
//     name: f('p140_t210', '活动'),
//     key: 'activity',
//   }
// ])

// const langs = [
//   {
//     name: '日本語',
//     key: 'jp',
//   },
//   {
//     name: 'English',
//     key: 'en',
//   },
//   {
//     name: '简体中文',
//     key: 'zh-cn',
//   },
// ]

/**
 * 切换语言
 * @param {String} lang zh-cn | en | jp
 */
function changeLang (lang) {
  locale.value = lang
  localStorage.setItem('lang', lang)

  adjustMenuWidth()

  window.location.reload()
}

// eslint-disable-next-line no-unused-vars
function changeCurrency (currency) {
  // locale.value = lang

  console.log(currency)
  localStorage.setItem('currency', currency)
  window.location.reload()
}

function gotoLogin () {
  router.push({ name: 'login' })
}

function gotoRegister () {
  router.push({ name: 'register' })
}

function goHome () {
  router.push({ path: '/' })
}

function menuClick (key) {
  router.push({ path: `/portal/${key}` })
}

function userActionClick (cmd) {
  if (cmd === 'logout') {
    userStore.logout()
    console.log('pathpathpathpath22', router.currentRoute.value.fullPath)
    if (router.currentRoute.value.fullPath == '/portal/home') {
      window.location.reload()
    } else {
      router.push({ path: '/' })
    }
  } else if (cmd === 'me') {
    router.push({ path: '/user' })
  } else if (cmd === 'mymessage') {
    router.push({ path: '/myMessage' })
  } else if (cmd === 'changePsw') {
    router.push({ path: '/changePassword' })
  } else if (cmd === 'resource') {
    router.push({ path: '/resourceInfo' })
  } else if (cmd === 'demand') {
    router.push({ path: '/demandInfo' })
  } else if (cmd === 'perspective') {
    router.push({ path: '/perspective' })
  }
}

/**
 * 跳转日本站点
 */
// eslint-disable-next-line no-unused-vars
function gotoJpSite () {
  window.open('//www.sponsorforce.net', '_blank')
}
</script>

<style>
.currency-icon {
  width: 25px;
  margin-right: 6px;
}
</style>

<style lang="scss" scoped>
$bwidth: 350px;

.header-container {
  // position: fixed;
  position: -webkit-sticky; /* Safari */  
  position: sticky;
  font-size: 18px;
  top: 0;
  background-color: #ffffff;
  z-index: 10;
  display: flex;
  // min-width: 1440px;
  width: 100%;
  height: 80px;
  // background-color: blueviolet;
  justify-content: space-between;

  .header-logo {
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 50px;
    cursor: pointer;
  }

  .header-menu {
    width: 80%;
    display: flex;
    font-size: 16px;
    align-items: center;
    // min-width: 750px;
    max-width: 850px;
    align-items: center;

    .menu-item {
      position: relative;
      display: inline-block;
      cursor: pointer;
      //  width: 200px;
      text-align: center;
      margin-right: 40px;
      box-sizing: border-box;

      &::after {
        content: "";
        position: absolute;
        bottom: 20px;
        left: 0px;
        width: 0;
        height: 1.5px;
        top: 100%;
        background-color: lighten($color: $primaryColor, $amount: 20%);
        transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
      }

      &:hover::after {
        width: 100%;
      }
    }

    span {
      display: inline-block;
      height: 80px;
      line-height: 80px;
      text-align: center;
      transition: ease-in-out 100ms;
      cursor: pointer;

      // &:hover {
      //   text-decoration: underline;
      //   text-decoration-color: lighten($color: $primaryColor, $amount: 20%);
      //   text-decoration-thickness: 2px;
      //   text-underline-offset: 10px; //下划线和文字间距
      // }

      // min-width: 100px;
      // width: 200px;
      // background-color: darkturquoise;
    }
  }

  .header-right {
    // margin-left: 0px;
    margin-right: 30px;
    width: auto;
    // min-width: 350px;
    // margin-left:30px;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.btn-pointer {
  cursor: pointer;
}

.primary-btn-1 {
  border-radius: 20px;
  min-width: 80px;
  padding: 3px 10px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  margin-right: 3px;
  background-color: #0c134d;
  color: #ffffff;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-3px);
  }
}

.primary-btn-2 {
  border-radius: 20px;
  min-width: 80px;
  padding: 3px 5px;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
}

.earth-btn {
  width: 20px;
  height: 20px;
}

.username {
  // font-size: 16px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.logo {
  width: 200px;
}

.lang-warp {
  .earth-btn {
    &:focus {
      outline: none;
    }
  }
}

.avatar {
  display: inline-block;
  outline: none;
  margin: 0 5px;
}

// 轮播css
.header-banner-container {
  background: #f2f6f6;
  text-align: center;
  padding: 5px 15px;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 10;
  transition: opacity 0.8s ease-in-out;
}

.hidden {
  opacity: 0;
}

.input-warp {
  width: 400px;
  height: 45px;
  // margin: auto;
  //  margin-top: 20px;
  //  margin-right: 20px;
  // margin:0 auto;
  margin-left: 38%;
  top: 140px;
  position: fixed;
  padding-left: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  // transform: translateX(-20px);

  input {
    border-radius: 30px;
    flex: 1;
    height: 100%;
    width: 380px;
    border: none;
    outline: none;
    font-size: 20px;
  }

  .btn-warp {
    width: 50px;
    height: 100%;
    // border-radius: 0 25px 25px 0;
    background-color: #010000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 20px;
    }
  }
}

.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(39, 33, 33, 0.25);
  z-index: 10;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

input {
  box-shadow: 10px 2px 16px rgb(13 15 20 / 40%);
}

.hide {
  opacity: 0;
  transform: translateY(-100px);
}
</style>
