<template>
  <el-config-provider :locale="elLocale">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { ref, watch } from 'vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import ja from 'element-plus/dist/locale/ja.mjs';
import { useFanyi } from '@/plugin/fanyi';

const langMap = {
  'zh-cn': zhCn,
  'en': en,
  'jp': ja,
};

const { locale } = useFanyi();
const elLocale = ref(langMap[locale.value] || ja);

watch(locale, (newLocale) => {
  elLocale.value = langMap[newLocale] || ja;
  document.body.className = newLocale;
});
</script>
